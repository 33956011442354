import { APIResponse, PaginatedData } from "@/modules/core/types";
import { defineStore } from "pinia";
import { MemberListing } from "../types";
import axios from "axios";
import endpoints from "../endpoints";
import { useErrorHandling } from "@/modules/core/composables";

export const useBroadcastPortalMembersListingStore = defineStore('broadcast-portal-members-listing', {
    state: () => ({
        paginatedData: null as PaginatedData<MemberListing>|null,
        filters: {
            searchTerms: '',
            memberType: '',
            pageNumber: 1,
            pageSize: 6
        },
        isBusy: false,
        activeMemberId: '',
    }),
    getters: {
        activeMember: state => state.paginatedData?.items.find(x => x.key === state.activeMemberId),
    },
    actions: {
        async loadMembersListing () {
            this.isBusy = true

            const request = axios.get<APIResponse<PaginatedData<MemberListing>>>(
                `${endpoints.MEMBERS}/search`,
                { params: this.filters }
            )

            const response = await useErrorHandling(request)

            this.paginatedData = response.data.data
            this.isBusy = false

            this.autoSetActiveMember();
        },
        parseUrl () {
            const currUrl = new URL(window.location.href);
            const searchParams = currUrl.searchParams;
            
            const searchTerms = searchParams.get('searchTerms') ?? '';
            const memberType = searchParams.get('memberType') ?? '';
            const pageNumber = parseInt(searchParams.get('pageNumber') ?? this.filters.pageNumber.toString());
            const pageSize = parseInt(searchParams.get('pageSize') ?? this.filters.pageSize.toString());
            const activeMember = searchParams.get('activeMemberId') ?? '';

            this.filters.searchTerms = searchTerms;
            this.filters.memberType = memberType;
            this.filters.pageNumber = pageNumber;
            this.filters.pageSize = pageSize;
            this.activeMemberId = activeMember;
        },
        updateUrl (replaceState = false) {
            const currUrl = new URL(window.location.href);
            const searchParams = currUrl.searchParams;
            
            searchParams.set('pageNumber', this.filters.pageNumber.toString());
            searchParams.set('pageSize', this.filters.pageSize.toString());
            searchParams.set('searchTerms', this.filters.searchTerms)
            searchParams.set('memberType', this.filters.memberType)
            searchParams.set('activeMemberId', this.activeMemberId)

            if (replaceState) {
                window.history.replaceState(null, '', currUrl.toString());
            } else {
                window.history.pushState(null, '', currUrl.toString());
            }
        },
        listenToHistory () {
            const popstateHandler = () => {
                this.parseUrl();
                this.loadMembersListing();
            }

            window.addEventListener('popstate', popstateHandler);

            return () => window.removeEventListener('popstate', popstateHandler)
        },
        autoSetActiveMember () {
            const newActiveMember = this.paginatedData?.items[0];
            this.$patch({ activeMemberId: newActiveMember?.key })
        },
        setActiveMember (memberId: string) {
            this.$patch({activeMemberId: memberId});
            this.updateUrl();
        }
    }
})