<template>
    <div class="gray-bg pb-5">
        <div class="container">
            <div class="row gy-3">
                <div class="col-md-10">
                    <input
                        id="txt-searchTerms"
                        name="searchTerms"
                        placeholder="Search through your services..."
                        v-model.lazy="filters.searchTerms"
                        @change="onSearchChange"
                        type="text">
                </div>
                <div class="col-md-2">
                    <button @click="onSearchChange" class="btn btn-secondary btn-lg w-100" type="button">Search</button>
                </div>
            </div>
        </div>
    </div>
    <div class="service-finder container pt-5 py-4">
        <FadeInOut mode="out-in" :duration="300">
            <GridPlaceholder
                v-if="isBusy"
                grid-config="1x3"
                grid-config-lg="3x2"
                grid-item-ratio="21x9"
            ></GridPlaceholder>
            <div v-if="paginatedData && paginatedData.count > 0">
                <p class="subheading text-muted">{{ paginationStatusLabel }}</p>
                <div class="row gy-3">
                    <div v-for="item in paginatedData?.items" class="col-md-6 col-lg-4">
                        <PackageServiceCard
                            v-if="item.serviceOffering.ncContentTypeAlias === 'packageServiceOffering'"
                            :service="item.serviceOffering"
                        >
                            <template #logo-info>
                                <div class="hstack gap-2">
                                    <Avatar
                                        v-if="item.logoIcon === null"
                                        :text="item.memberName"
                                        class="avatar-sm"
                                    ></Avatar>
                                    <img v-if="item.logoIcon" :src="item.logoIcon.url" :alt="item.logoIcon.name" class="img-company-logo">
                                    <p class="caption fw-semibold text-muted mb-0">{{ item.memberName }}</p>
                                </div>
                            </template>
                        </PackageServiceCard>
                        <IndividualServiceCard
                            v-if="item.serviceOffering.ncContentTypeAlias === 'individualServiceOffering'"
                            :service="item.serviceOffering"
                        >
                            <template #logo-info>
                                <div class="hstack gap-2">
                                    <img v-if="item.logoIcon !== null" :src="item.logoIcon.url" :alt="item.logoIcon.name" class="img-fluid img-company-logo">
                                    <p class="caption fw-semibold text-muted mb-0">{{ item.memberName }}</p>
                                </div>
                            </template>
                        </IndividualServiceCard>
                    </div>
                </div>
            </div>
            <div v-else>
                <p class="display-l text-muted">No services can be found...</p>
            </div>
        </FadeInOut>
        <div class="d-flex pt-4">
            <Pagination
                v-if="paginatedData?.currentPageNumber"
                :page-number="paginatedData?.currentPageNumber"
                :total-pages="paginatedData?.totalPages"
                :pages-per-view="paginatedData.pageSize"
                @page-change="onPageChange"
            ></Pagination>
        </div>
    </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useBroadcastPortalServiceListingStore } from '../../store';
import { FadeInOut } from 'vue3-transitions';
import GridPlaceholder from '@/modules/core/components/placeholders/grid-placeholder.vue';
import PackageServiceCard from './service-card/package-service-card.vue';
import IndividualServiceCard from './service-card/individual-service-card.vue';
import Pagination from '@/modules/core/components/pagination/pagination.vue';
import { computed, ref } from 'vue';
import Avatar from '@/modules/core/components/avatar.vue';

const broadcastPortalServiceStore = useBroadcastPortalServiceListingStore();
const { paginatedData, isBusy, filters } = storeToRefs(broadcastPortalServiceStore);

// const min = ref(0)
// const max = ref(0)

const paginationStatusLabel = computed(() => {
    if (!paginatedData) return '';

    const min = ((paginatedData.value!.currentPageNumber - 1) * paginatedData.value!.pageSize) + 1;
    const max = Math.min(paginatedData.value!.currentPageNumber * paginatedData.value!.pageSize, paginatedData.value!.count);

    return `Showing ${min} - ${max} of ${paginatedData.value!.count}`;
})

const onPageChange = (pageNumber: number) => {
    filters.value.pageNumber = pageNumber;
    broadcastPortalServiceStore.loadServiceListings();
}

const onSearchChange = () => {
    broadcastPortalServiceStore.filters.pageNumber = 1;
    broadcastPortalServiceStore.loadServiceListings();
}

broadcastPortalServiceStore.parseUrl();
broadcastPortalServiceStore.loadServiceListings();
broadcastPortalServiceStore.listenToHistory()
</script>

<style lang="scss" scoped>
.service-finder {
    input::placeholder {
        font-style: normal;
        color: red;
    }
    .img-company-logo {
        max-height: 20px;
        max-width: 100px;
    }

    .avatar.avatar-default {
        max-height: 20px;
        max-width: 20px;
    }
}
</style>