<template>
    <div class="gray-bg py-5">
        <div class="container">
            <h1 class="heading-l fw-bold">Find Production Houses & Freelancers</h1>
            <div class="row gx-3 gy-3 align-items-md-center">
                <div class="col-md-7">
                    <input
                        id="txt-searchTerms"
                        name="searchTerms"
                        placeholder="Search by keywords..."
                        v-model.lazy="filters.searchTerms"
                        @change="onSearchChange"
                        type="text">
                </div>
                <div class="col-md-3 mt-md-0">
                    <select
                        class="form-select border mb-0"
                        aria-label="Select talents"
                        id="sel-memberType"
                        name="memberType"
                        v-model.lazy="filters.memberType"
                        @change="onSearchChange">
                        <option :value="''">All</option>
                        <option :value="'ProductionHouseMember'">Production House</option>
                        <option :value="'FreelanceMember'">Freelancer</option>
                    ></select>
                </div>
                <div class="col-md-2 mt-md-0">
                    <button @click="onSearchChange" class="btn btn-secondary btn-lg w-100" type="button">Search</button>
                </div>
            </div>
        </div>
    </div>
    <div class="container pt-5">
        <Finder
            :item-length="paginatedData?.count"
            :paginated-results="paginatedData || undefined"
            :is-busy="isBusy"
            :search-term="memberListingStore.filters.searchTerms">
            <template #filter-value v-if="filters.searchTerms.replace(/\s+/g, '')">
                for <span class="text-secondary">{{filters.searchTerms}}</span>
            </template>
        
            <template #list-item="{ item }">
                <FindMemberListItem
                    :item="item"
                    @click="setActiveMemberListing($event, item)"
                ></FindMemberListItem>
            </template>
            <template #details>
                <div class="hstack gap-3 gap-lg-4 mb-4 mb-lg-5 placeholder-glow">
                    <Avatar
                        :text="activeMember?.name"
                        :image-url="activeMember?.logo?.url"
                        class="avatar-3xl border">
                    ></Avatar>
                    <div class="flex-grow-1 d-flex flex-column">
                        <template v-if="!isBusy">
                            <div class="d-flex">
                                <div v-if="activeMember?.typeAlias === 'productionHouseMember'" class="badge bg-secondary text-white rounded-pill"><span>Production House</span></div>
                                <div v-else class="badge bg-primary text-white rounded-pill">Freelancer</div>
                            </div>
                            <h3 class="display-m fw-bold my-2">
                                <a :href="`/talents/member-details?memberId=${activeMember?.id}`"
                                    class="text-decoration-none link-primary text-dark">
                                    {{ activeMember?.name }}
                                </a>
                            </h3>
                            <p v-if="activeMember?.showFocalEmailInPortal" class="caption text-gray-500 fw-semibold mb-0">{{ activeMember?.focalEmailAddress }}</p>
                        </template>
                        <template v-else>
                            <div class="placeholder bg-primary w-25 subheading mb-2"></div>
                            <div class="placeholder bg-primary w-75 display-m mb-2"></div>
                            <div class="placeholder bg-primary w-50 subheading"></div>
                        </template>
                    </div>
                </div>
                <Tabs nav-tabs-class="d-flex flex-column flex-md-row ps-0">
                    <TabPane
                        id="txt-about"
                        title="About"
                    >
                        <div class="flex-grow-1 d-flex flex-column">
                            <template v-if="!isBusy">
                                <div v-if="activeMember?.typeAlias === 'productionHouseMember'">
                                    <h3 class="body-s fw-bold">About the Organisation</h3>
                                    <p v-if="activeMember.introduction !== ''" class="text-gray-500">{{ strippedHtml }}</p>
                                    <p v-else class="text-gray-500">-</p>
                                </div>
                                <div v-else>
                                    <h3 class="body-s fw-bold">About the individual</h3>
                                    <p v-if="activeMember!.introduction !== ''" class="text-gray-500">{{ strippedHtml }}</p>
                                    <p v-else class="text-gray-500">-</p>
                                </div>
                                <div class="row">
                                    <div class="col-5">
                                        <div class="hstack gap-2">
                                            <Icon symbol="mail"></Icon>
                                            <p class="caption text-gray-500 fw-semibold mb-0">Contact Email</p>
                                        </div>
                                        <div class="pe-4">
                                            <div class="card border-0 bg-transparent">
                                                <p v-if="activeMember!.showFocalEmailInPortal === true" class="mt-1 fw-bold">{{activeMember?.focalEmailAddress}}</p>
                                                <p v-else class="text-muted mt-1">-</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-5">
                                        <div class="hstack gap-2">
                                            <Icon symbol="phone"></Icon>
                                            <p class="caption text-gray-500 fw-semibold mb-0">Contact Phone Number</p>
                                        </div>
                                        <div class="card border-0 bg-transparent">
                                            <p v-if="activeMember!.showFocalContactNumberInPortal === true" class="mt-1 fw-bold">{{ activeMember?.focalContactNumber }}</p>
                                            <p v-else class="text-muted mt-1">-</p>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div class="placeholder bg-primary w-25 subheading mb-2"></div>
                                <div class="placeholder bg-primary w-75 display-m mb-2"></div>
                                <div class="placeholder bg-primary w-50 subheading"></div>
                            </template>
                        </div>
                    </TabPane>
                    <TabPane
                        id="txt-portfolio"
                        title="Portfolio"
                    >
                        <GridPlaceholder
                            v-if="isBusy"
                            grid-config="1x1"
                            grid-config-lg="2x2"
                            grid-item-ratio="16x9"
                        ></GridPlaceholder>
                        <div v-else class="row gy-4">
                            <div
                                v-if="activeMember?.portfolioShowcases !== null && getPublicPortfolios!.length > 0"
                                v-for="portfolio in getPublicPortfolios"
                                class="col-lg-6">
                                <FindMemberPortfolioCard
                                    v-if="portfolio != null"
                                    :portfolio="portfolio"
                                ></FindMemberPortfolioCard>
                                <div v-else>
                                    <p class="body-l text-muted">This talent currently has no public portfolios...</p>
                                </div>
                            </div>
                            <div v-else>
                                <p class="body-l text-muted">This talent currently has no public portfolios...</p>
                            </div>
                        </div>
                    </TabPane>
                    <TabPane
                        id="txt-services"
                        title="Services"
                    >
                        <GridPlaceholder
                            v-if="isBusy"
                            grid-config="1x1"
                            grid-config-lg="2x2"
                            grid-item-ratio="4x3"
                        ></GridPlaceholder>
                        <div v-else class="row gy-3">
                            <div
                                v-if="activeMember?.serviceOfferings !== null && getPublicServices!.length > 0"
                                v-for="service in getPublicServices"
                                class="col-lg-6">
                                <FindMemberPackageServiceCard
                                    v-if="service.ncContentTypeAlias === 'packageServiceOffering'"
                                    :service="service"
                                ></FindMemberPackageServiceCard>
                                <FindMemberIndividualServiceCard
                                    v-if="service.ncContentTypeAlias === 'individualServiceOffering'"
                                    :service="service"
                                ></FindMemberIndividualServiceCard>
                            </div>
                            <div v-else>
                                <p class="body-l text-muted">This talent currently has no public services...</p>
                            </div>
                        </div>
                    </TabPane>
                    <TabPane
                        id="txt-advertisements"
                        title="Advertisements"
                    >
                        <GridPlaceholder
                            v-if="isBusy"
                            grid-config="1x1"
                            grid-config-lg="2x2"
                            grid-item-ratio="4x3"
                        ></GridPlaceholder>
                        <div v-else class="row gy-3">
                            <div
                                v-if="activeMember?.advertisements !== null && getPublicAdvertisements!.length > 0"
                                v-for="advertisement in getPublicAdvertisements"
                                class="col-lg-6">
                                <FindMemberAdvertisementCard
                                    :advertisement="advertisement"
                                    :member-name="activeMember!.name"
                                    :member-logo="activeMember!.logo.url"
                                    :member-logo-name="activeMember!.logo.name">
                                </FindMemberAdvertisementCard>
                            </div>
                            <div v-else>
                                <p class="body-l text-muted">This talent currently has no public advertisements...</p>
                            </div>
                        </div>
                    </TabPane>
                </Tabs>
            </template>
        
            <template #pagination>
                <Pagination
                    v-if="paginatedData?.currentPageNumber"
                    :page-number="paginatedData?.currentPageNumber"
                    :total-pages="paginatedData?.totalPages"
                    :pages-per-view="paginatedData.pageSize"
                    @page-change="onPageChange"
                ></Pagination>
            </template>
        </Finder>
    </div>

</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { Icon, Avatar, Pagination, Finder, VLazyImage } from '@/modules/core/components';
import { useBroadcastPortalMembersListingStore } from '@/modules/broadcast-portal/store';
import { breakpointsBootstrapV5, useBreakpoints } from '@vueuse/core';
import FindMemberListItem from './find-member-list-item.vue';
import { MemberListing } from '../../types';
import Tabs from '@/modules/core/components/tabs/tabs.vue';
import TabPane from '@/modules/core/components/tabs/tab-pane.vue';
import FindMemberPortfolioCard from './find-member-portfolio-card.vue';
import FindMemberPackageServiceCard from './find-member-package-service-card.vue';
import FindMemberIndividualServiceCard from './find-member-individual-service-card.vue';
import GridPlaceholder from '@/modules/core/components/placeholders/grid-placeholder.vue';
import { computed } from 'vue';
import FindMemberAdvertisementCard from './find-member-advertisement-card.vue';

const breakpoint = useBreakpoints(breakpointsBootstrapV5)
const mdAndGreater = breakpoint.smaller('lg');

const memberListingStore = useBroadcastPortalMembersListingStore();
const { filters, paginatedData, activeMember, isBusy } = storeToRefs(memberListingStore)

const jobSearch = async () => {
    await memberListingStore.loadMembersListing();
    memberListingStore.updateUrl()
}

const isExpired = (endDate: string) => {
    if (!endDate) return false 

    const currentDate = new Date();
    const date = new Date(endDate);
    return date < currentDate
}

const getPublicPortfolios = computed(() => {
    let publicPortfolio = activeMember.value?.portfolioShowcases.filter(x => x.approvalStatus.name === 'Approved' && x.showInPortal === true)
    return publicPortfolio
})

const getPublicServices = computed(() => {
    let publicService = activeMember.value?.serviceOfferings.filter(x => x.approvalStatus.name === 'Approved' && x.showOfferingInPortal === true)
    return publicService
})

const getPublicAdvertisements = computed(() => {
    let publicAdvertisements = activeMember.value?.advertisements.filter(x => x.approvalStatus.name === 'Approved' && x.isPublic === true && !isExpired(x.endDate))
    return publicAdvertisements
})

const setActiveMemberListing = async (event: Event, item: MemberListing) => {
    if(mdAndGreater.value) return;
    event.preventDefault();
    memberListingStore.setActiveMember(item.key);
}

const onPageChange = async (pageNumber: number) => {
    const currentPage = memberListingStore.filters.pageNumber;
    if (currentPage !== pageNumber) {
        memberListingStore.filters.pageNumber = pageNumber;
        await memberListingStore.loadMembersListing();
        memberListingStore.updateUrl();
    }
}

const onSearchChange = () => {
    memberListingStore.filters.pageNumber = 1;
    jobSearch()
}

const strippedHtml = computed(() => {
    if (activeMember.value?.introduction === '') return
    let regex = /(<([^>]+)>)/ig;

    return activeMember.value?.introduction.replace(regex, '')
})

jobSearch()

</script>

<style lang="scss">
.search-results {
    padding-bottom: 3rem;

    .search-results-list {
        &.list-unstyled {
            list-style: none;
        }
    }

    .finder-viewer {

        .avatar.avatar-default {
            // width: 2.25rem !important;
            border-radius: 0.5rem !important;
        }

        .nav.nav-tabs {
            list-style: none;

            .nav-link {
                color: $primary;
                padding: 0.45rem 1rem;
                border-radius: 0.5rem;

                &.active {
                    color: $light;
                    background-color: $primary;
                }
            }

            .nav-link::after {
                border-color: transparent;
            }

            .nav-link:hover:after {
                border-color: transparent;
            }
        }

        .tab-content {
            .tab-pane {
                &.active.show {
                    display: block;
                }

                display: none;
            }
        }

        .nav-tabs::after {
            display: none;
        }
    }
}
</style>