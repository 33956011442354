<script lang="ts">
import { defineComponent } from 'vue';
import AdvertisementBadge from './advertisement-badge.vue';

export default defineComponent({
    components: {
        AdvertisementBadge
    }
})
</script>
