<template>
    <Modal v-if="bcfListingDetail" @hidden="stopVideos" modal-title="Submission Detail" size="modal-lg" class="bcf-submission-detail-modal modal-upload-content">
        <template #activator="{ show }">
            <div class="vstack text-decoration-none text-dark">
                <h2 class="heading-l fw-semibold text-gray-700 pe-lg-4">{{ bcfListingDetail.contentTitleName }}</h2>
                <div class="d-flex gap-3 flex-wrap">
                    <div v-if="bcfListingDetail.submissionYear === 2024" class="hstack gap-2">
                        <Icon symbol="options-lines" class="icon-m text-muted"></Icon>
                        <p class="caption text-gray-500 mb-0">{{ getCategoryFullName(bcfListingDetail.category) }}</p>
                    </div>
                    <div v-if="bcfListingDetail.submissionYear === 2024 && bcfListingDetail.subCategory" class="hstack gap-2">
                        <Icon symbol="grid-fill" class="icon-m text-muted"></Icon>
                        <p class="caption text-gray-500 mb-0">{{ getCategoryFullName(bcfListingDetail.subCategory) }}</p>
                    </div>
                    <div class="hstack gap-2">
                        <Icon symbol="people-fill" class="icon-m text-muted"></Icon>
                        <p v-if="bcfListingDetail.companyName" class="caption text-gray-500 mb-0">{{ bcfListingDetail.companyName }}</p>
                        <p v-else class="caption text-gray-500 mb-0">{{ bcfListingDetail.firstName }} {{ bcfListingDetail.lastName }}</p>
                    </div>
                    <div class="hstack gap-2">
                        <Icon symbol="calendar" class="icon-m"></Icon>
                        <p class="caption text-gray-500 mb-0">
                            {{ formatDateAsString(bcfListingDetail.submissionDate, 'DD MMM YYYY') }}
                        </p>
                    </div>
                </div>
                <div class="d-flex">
                    <a 
                        v-if="bcfListingDetail.isUsingExternalLink" 
                        :href="bcfListingDetail.externalLink" 
                        target="_blank"
                        class="btn btn-lg btn-outline-secondary rounded-pill mt-3 stretched-link caption fw-bold">
                        Watch Video
                    </a>
                    <button 
                        v-else 
                        @click="show" 
                        class="btn btn-lg btn-outline-secondary rounded-pill mt-3 stretched-link caption fw-bold" 
                        type="button">
                        Watch video
                    </button>
                </div>
            </div>
        </template>
        <div class="card card-advertisement border-0 h-100 bg-transparent">
            <div class="card-body p-4 vstack justify-content-between">
                <div class="position-relative">
                    <div class="ratio ratio-16x9">
                        <iframe
                            v-if="bcfListingDetail.videoUID"
                            :src="`https://customer-fp0mjytj2t8cinei.cloudflarestream.com/${bcfListingDetail.videoUID}/iframe`"
                            style="border: none; background: #ffffff"
                            allowtransparency="true"
                            height="720"
                            width="1280"
                            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                            allowfullscreen="true"
                        ></iframe>
                        <div v-else class="bg-gray-200 d-flex align-items-center justify-content-center">
                            <p class="mb-0 heading-s text-muted">No Image</p>
                        </div>
                    </div>
                </div>
                <div class="vstack flex-grow-1">
                    <a class="text-decoration-none">
                        <h3 class="heading-l fw-semibold text-gray-700 mt-3 pe-lg-4">{{ bcfListingDetail.contentTitleName }}</h3>
                    </a>
                    <!-- <p class="body-s text-gray-500 mb-3">{{ bcfListingDetail }}</p> -->
                    <div class="d-flex justify-content-between">
                        <div>
                            <div class="hstack gap-3">
                                <div class="hstack gap-2">
                                    <Icon symbol="people-fill" class="icon-m text-muted"></Icon>
                                    <p v-if="bcfListingDetail.companyName" class="caption text-gray-500 mb-0">{{ bcfListingDetail.companyName }}</p>
                                    <p v-else class="caption text-gray-500 mb-0">{{ bcfListingDetail.firstName }} {{ bcfListingDetail.lastName }}</p>
                                </div>
                                <div class="hstack gap-2">
                                    <Icon symbol="calendar" class="icon-m"></Icon>
                                    <p class="caption text-gray-500 mb-0">
                                        {{ formatDateAsString(bcfListingDetail.submissionDate, 'DD MMM YYYY') }}
                                    </p>
                                </div>
                                <div class="d-flex">
                                    <div class="badge px-2 rounded-pill text-capitalize text-white text-bg-primary"
                                        :class="{
                                            'text-bg-primary': bcfListingDetail.category === 'industry',
                                            'text-bg-secondary': bcfListingDetail.category === 'open'
                                        }">
                                        <span v-if="bcfListingDetail.category === 'industry'">Industry Category</span>
                                        <span v-if="bcfListingDetail.category === 'open'">Open Category</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Modal>
</template>

<script setup lang="ts">
import { Icon, Modal } from '@/modules/core/components';
import { formatDateAsString } from '@/modules/core/utilities';
import { BcfListing } from '../../types';
import { onBeforeUnmount, ref } from 'vue';
import { getCategoryFullName } from '@/modules/broadcast-portal/components';

interface Props {
    bcfListingDetail: BcfListing;
    index?: number;
}
const props = defineProps<Props>()

const stopVideos = () => {
    document.querySelectorAll('iframe').forEach(item => { item.src = item.src });
    document.querySelectorAll('video').forEach(item => { item.pause() });
};

onBeforeUnmount(() => {
    stopVideos();
});
</script>

<style lang="scss">
</style>