<template>
    <Modal modal-title="Portfolio Video" size="modal-lg" class="modal-upload-content">
        <template #activator="{ show }">
            <div class="card border-0 h-100 bg-transparent">
                <div class="card-body p-0 vstack">
                    <a>
                        <div class="position-relative">
                            <div v-if="props.portfolio.portfolioVideoId !== ''" class="badge-video position-absolute">
                                <div class="badge bg-white rounded-pill text-danger hstack px-2">
                                    <Icon symbol="play-fill" class="icon-m"></Icon>
                                    Video

                                    <!-- Hide until a fix for video duration is found -->
                                    <!-- <div class="px-2">
                                        <div class="rounded pb-1 ps-1 bg-danger"></div>
                                    </div>
                                    <span>{{ toTimeString(props.portfolio.portfolioVideoDurationInSeconds) }}</span> -->
                                </div>
                            </div>
                            <div class="ratio ratio-16x9">
                                <VLazyImage
                                    :src="props.portfolio.image.url"
                                    :alt="props.portfolio.image.name"
                                    class="v-lazy-image-fades-in"
                                ></VLazyImage>
                            </div>
                        </div>
                    </a>
                    <div class=" flex-grow-1">
                        <a class="text-decoration-none">
                            <h3 class="heading-l text-gray-700 mt-3 pe-lg-4">{{ props.portfolio.title }}</h3>
                        </a>
                        <p class="body-s text-gray-500 mb-5 line-clamp" style="--line-clamp-lines: 3">{{ strippedHtml }}</p>
                    </div>
                    <p class="subheading text-gray-500 mb-4">
                        {{ formatDateAsString(props.portfolio.createDateTime, 'DD MMM YYYY') }}
                    </p>
                    <div class="d-flex gap-2">
                        <a
                            v-if="uploadedVideoUrl !== null && props.portfolio.portfolioVideoId !== ''"
                            @click.prevent="show"
                            :href="props.portfolio.url"
                            class="text-decoration-none">
                            <h3 class="btn btn-secondary subheading mb-0">View Video</h3>
                        </a>
                        <a :href="portfolio.url" target="_blank" class="btn btn-outline-dark border subheading">View Portfolio</a>
                    </div>
                </div>
            </div>
        </template>

        <template #="{isActive}">
            <div v-if="isActive" class="p-3">
                <div class="row justify-content-center">
                    <GridPlaceholder
                        v-if="isLoading"
                        grid-config="1x1"
                        grid-config-lg="1x1"
                        grid-item-ratio="16x9"
                    ></GridPlaceholder>
                    <div v-else class="col-lg-8">
                        <div class="ratio ratio-16x9 portfolio-video bg-black">
                            <div class="spinner-container">
                                <Spinner class="video-spinner"></Spinner>
                            </div>
                            <iframe
                                v-if="portfolio.portfolioVideoId"
                                :src="`https://customer-fp0mjytj2t8cinei.cloudflarestream.com/${portfolio.portfolioVideoId}/iframe`"
                                style="border: none;"
                                allowtransparency="true"
                                height="720"
                                width="1280"
                                allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                                allowfullscreen="true"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script setup lang="ts">
import { PortfolioShowcase } from '../../types';
import { formatDateAsString } from '@/modules/core/utilities';
import VLazyImage from '@/modules/core/components/v-lazy-image.vue';
import Icon from '@/modules/core/components/icon.vue';
import Modal from '@/modules/core/components/modal.vue';
import { computed, onBeforeUnmount, ref } from 'vue';
import GridPlaceholder from '@/modules/core/components/placeholders/grid-placeholder.vue';
import { Spinner } from '@/modules/core/components';

interface Props {
    portfolio: PortfolioShowcase
}
const props = defineProps<Props>()

const uploadedVideoUrl = ref('')
const isLoading = ref(false)

const strippedHtml = computed(() => {
    if (props.portfolio.description === '') return
    let regex = /(<([^>]+)>)/ig;

    return props.portfolio.description.replace(regex, '')
})

</script>

<style lang="scss" scoped>
.card-body {
    .badge-video {
        z-index: 3;
        top: 8%;
        left: 4%;
    }

    .badge-portfolio {
        z-index: 3;
        bottom: 8%;
        right: 4%;

        .badge-approved {
            background-color: #e8f3ee;
        }

        .badge-pending {
            background-color: #fff9e6;
        }

        .badge-rejected {
            background-color: #ffd9dd;
        }
    }

    img {
        border-radius: 1rem;
    }
}

.ratio-16x9 {
    .portfolio-video {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.video-spinner {
    width: 48px;
    height: 48px;
}
</style>