import { defineStore } from "pinia";
import { ServiceListings } from "../types";
import { APIResponse, PaginatedData } from "@/modules/core/types";
import axios from "axios";
import endpoints from "../endpoints";
import { useErrorHandling } from "@/modules/core/composables";

export const useBroadcastPortalServiceListingStore = defineStore('broadcast-portal-service-listing', {
    state: () => ({
        paginatedData: null as PaginatedData<ServiceListings>|null,
        filters: {
            searchTerms: '',
            pageNumber: 1,
            pageSize: 9
        },
        isBusy: false
    }),
    actions: {
        async loadServiceListings () {
            this.isBusy = true

            const request = axios.get<APIResponse<PaginatedData<ServiceListings>>>(
                `${endpoints.SERVICE}/search`,
                { params: this.filters }
            )

            const response = await useErrorHandling(request)

            this.paginatedData = response.data.data
            this.isBusy = false
        },
        parseUrl () {
            const currUrl = new URL(window.location.href);
            const searchParams = currUrl.searchParams;
            
            const searchTerms = searchParams.get('searchTerms') ?? '';
            const pageNumber = parseInt(searchParams.get('pageNumber') ?? this.filters.pageNumber.toString());
            const pageSize = parseInt(searchParams.get('pageSize') ?? this.filters.pageSize.toString());

            this.filters.searchTerms = searchTerms;
            this.filters.pageNumber = pageNumber;
            this.filters.pageSize = pageSize;
        },
        updateUrl (replaceState = false) {
            const currUrl = new URL(window.location.href);
            const searchParams = currUrl.searchParams;
            
            searchParams.set('pageNumber', this.filters.pageNumber.toString());
            searchParams.set('pageSize', this.filters.pageSize.toString());
            searchParams.set('searchTerms', this.filters.searchTerms)

            if (replaceState) {
                window.history.replaceState(null, '', currUrl.toString());
            } else {
                window.history.pushState(null, '', currUrl.toString());
            }
        },
        listenToHistory () {
            const popstateHandler = () => {
                this.parseUrl();
                this.loadServiceListings();
            }

            window.addEventListener('popstate', popstateHandler);

            return () => window.removeEventListener('popstate', popstateHandler)
        }
    }
})