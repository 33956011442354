<template>
    <div class="card card-find-member-result-list-item border rounded-4"
        :class="{ 'is-active': item.key === activeMemberId }">
        <div class="card-body d-flex flex-column">
            <div class="hstack gap-3">
                <Avatar
                    :image-url="item?.logo?.url"
                    :text="item.name"
                    class="avatar-xl border company-logo">
                ></Avatar>
                <div class="d-flex flex-column justify-content-center gap-1">
                    <h3 class="body-s fw-bold mb-0">
                        <a :href="`/find-members/member-details?memberId=${item?.id}`" class="text-decoration-none stretched-link link-primary text-dark">{{ item?.name }}</a>
                    </h3>
                    <div class="">
                        <p v-if="item.typeAlias === 'productionHouseMember'" class="caption text-secondary fw-semibold mb-0">Production House</p>
                        <p v-else class="caption text-primary fw-semibold mb-0">Freelancer</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useBroadcastPortalMembersListingStore } from '../../store';
import { MemberListing } from '../../types';
import Avatar from '@/modules/core/components/avatar.vue';

interface Props {
    item: MemberListing
}
defineProps<Props>()

const memberListingStore = useBroadcastPortalMembersListingStore();
const { activeMemberId } = storeToRefs(memberListingStore);
</script>

<style lang="scss" scoped>
.card-find-member-result-list-item {
    border-color: $gray-200 !important;

    .avatar.avatar-alphabet.avatar-default.ratio.company-logo {
        // width: 2.25rem !important;
        border-radius: 1rem !important;
        background-color: $gray-100;
    }

    .ratio img {
        object-fit: contain !important;
    }
}
</style>