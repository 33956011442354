<template>
    <div class="card border-0 shadow bg-transparent h-100">
        <div class="card-body card-service bg-white p-3 p-lg-4 shadow-sm vstack">
            <div class="d-flex">
                <div class="badge bg-primary rounded-pill p-2 text-white">Individual</div>
            </div>
            <a
                :href="`/find-services/service-details?serviceKey=${service.key}`"
                class="stretched-link text-decoration-none">
                <h3 class="heading-l text-gray-700 my-3 pe-lg-4">{{ service.service.name }}</h3>
            </a>
            <p v-if="props.service.description" class="body-s text-gray-500 mb-4 line-clamp" style="--line-clamp-lines: 3">
                {{ service.description }}
            </p>
            <div class="vstack">
                <div class="vstack mt-3 gap-1 justify-content-end">
                    <div class="hstack gap-2">
                        <Icon symbol="dollar"></Icon>
                        <p v-if="service.showPriceInPortal === true" class="mb-0 fw-bold">BND {{ service.price }}</p>
                        <p v-else class="mb-0 fw-bold">Price upon request</p>
                    </div>
                    <div class="hstack gap-2">
                        <Icon symbol="timewatch"></Icon>
                        <p v-if="service.durationOfWorkMeasurement && !service.durationIsDependentOnProject" class="text-muted mb-0">{{ service.durationOfWork }} {{ service.durationOfWorkMeasurement.name }}</p>
                        <p v-else class="text-muted mb-0">Duration depends on project</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { Icon } from '@/modules/core/components';
import { IndividualService } from '../../types';

interface Props {
    service: IndividualService
}
const props = defineProps<Props>()
</script>

<style scoped>

</style>