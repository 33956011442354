import { createPinia } from 'pinia';
// import { portalAuthPlugin } from './auth';

const pinia = createPinia()

// pinia.use(portalAuthPlugin)

export default pinia;

export * from './services';
export * from './members';
export * from './bcf';