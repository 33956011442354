<template>
    <div class="finder" :class="{ 'placeholder-glow': !isBusy }">
        <div v-if="itemLength !== 0" class="container mb-4 d-lg-flex justify-content-between align-items-center">
            <p class="text-muted heading-m m-lg-0">
                <p class="subheading text-muted mb-0">{{ paginationStatusLabel }}</p>
                <slot name="filter-value"></slot>
            </p>
            
            <slot name="sort-filter"></slot>
        </div>
        <div v-if="itemLength !== 0" class="search-results container">
            <div class="search-results-list-wrapper">
                <ListPlaceholder v-if="isBusy" :count="5"></ListPlaceholder>
                <ul v-if="!isBusy" class="search-results-list list-unstyled ps-0 mb-4">
                    <li v-for="item in props.paginatedResults?.items">
                        <slot name="list-item" :item="item"></slot>
                    </li>
                </ul>
                <div class="d-flex justify-content-sm-middle">
                    <slot name="pagination"></slot>
                </div>
            </div>

            <div class="finder-viewer border rounded-3 mb-lg-5">
                <div class="finder-viewer-body">
                    <slot v-if="itemLength !== 0" name="details"></slot>
                </div>
                <div v-if="$slots['details-footer']" class="finder-viewer-footer">
                    <slot name="details-footer"></slot>
                </div>
            </div>
        </div>
    </div>
    <div v-if="itemLength === 0 && !isBusy" class="container mb-5 general-empty-state">
        <h4 class="display-l text-gray-700 mb-5">
            The search <span class="text-secondary"> {{ searchTerm }}</span> did not match...
        </h4>

        <div class="general-empty-state-suggestion body-l text-gray-700 border rounded-3 p-4">
            <p class="fw-bold">You may want to try:</p>
            <ul class="body-s">
                <li>Try more general keyword</li>
                <li>Check your spelling</li>
                <li>Replace abbreviations with the entire word</li>
            </ul>
        </div>
    </div>
</template>

<script setup lang="ts">
import { PaginatedData } from '@/modules/core/types';
import { PropType, computed } from 'vue';
import { ListPlaceholder } from '@/modules/core/components';

// const paginatedResults = useFinderStore();

interface Props {
    paginatedResults?: PaginatedData<any>;
    isBusy?: boolean;
    itemLength?: number;
    searchTerm?: string;
}
const props = defineProps<Props>()

// count = 54
// page size = 10

// [10, 10, 10, 10, 10, 4]
// ['1-10', '11-20', ..., '51-54']

// current pageNumber = 1

const paginationStatusLabel = computed(() => {
    if (!props.paginatedResults) return '';

    const { count, pageSize, currentPageNumber, totalPages } = props.paginatedResults;

    const min = ((currentPageNumber - 1) * pageSize) + 1;
    const max = Math.min(currentPageNumber * pageSize, count);

    return `Showing ${min} - ${max} of ${count}`;
})
</script>

<style lang="scss">
.finder {
    .search-results {
        &-list {
            display:flex;
            flex-direction: column;
            gap: 0.75rem;
        }
    }

    @include media-breakpoint-up (lg) {
        .search-results {
            display: grid;
            grid-template-columns: minmax(0, 4fr) 8fr;
            gap: 1rem;
            align-items: flex-start;
        }
    } 
}

.finder-viewer {
    display: none;

    &-body {
        padding: $container-padding-x;
    }

    &-footer {
        position: sticky;
        padding: $container-padding-x;
        bottom: 0;
        border-top: 1px solid $gray-300;
        background-color: white;
    }

    ul {
        li::marker {
            color: $secondary;
            padding-left: 0;
        }
    }

    @include media-breakpoint-up (lg) {
        max-height: calc(100vh - 70px);
        position: sticky;
        top: 70px;
        overflow-y: auto;
        display: block;

        &-body {
            padding: 2rem;
        }

        &-footer {
            padding: 1rem 2rem;
        }
    }
}

.general-empty-state {
    &-suggestion {
        width: fit-content;
    }
}
</style>