<!-- We dont need a template as the template is in the cshtml file -->

<script lang="ts">
import { defineComponent } from 'vue'
import { GenericCarousel, ShareIcons, Icon, Avatar } from '@/modules/core/components'

export default defineComponent({
    components: {
        GenericCarousel,
        ShareIcons,
        Icon,
        Avatar,
    }
})
</script>
