<template>
    <div class="p-3">
        <div class="row justify-content-center">
            <div class="col-lg-8">
                <div class="ratio ratio-16x9 bg-black">
                    <div class="spinner-container">
                        <Spinner class="video-spinner"></Spinner>
                    </div>
                    <iframe
                        v-if="uploadedVideoUrl"
                        :src="`https://customer-fp0mjytj2t8cinei.cloudflarestream.com/${uploadedVideoUrl}/iframe`"
                        style="border: none;"
                        allowtransparency="true"
                        height="720"
                        width="1280"
                        allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                        allowfullscreen="true"
                    ></iframe>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { usePortfolioStore } from '@/modules/broadcast-portal-post-login/store'
import { Spinner } from '@/modules/core/components';
import { ref } from 'vue';

interface Props {
    portfolioVideoId: string
    portfolioTitle: string
}
const props = defineProps<Props>()

const uploadedVideoUrl = ref('')
const isUploaded = ref(false)

const portfolioStore = usePortfolioStore();

const getUploadedVideoTrailer = async () => {
    if (props.portfolioVideoId){
        isUploaded.value = true;
        const value = await portfolioStore.getPortfolioVideoTrailer(props.portfolioVideoId)
        uploadedVideoUrl.value = value;
    }
}

getUploadedVideoTrailer()

</script>

<style lang="scss" scoped>
.ratio-16x9 {
    display: flex;
    align-items: center;
    justify-content: center;
}

.spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.video-spinner {
    width: 48px;
    height: 48px;
}
</style>