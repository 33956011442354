import { APIResponse, PaginatedData } from "@/modules/core/types";
import { defineStore } from "pinia";
import { Advertisement } from "../types";
import axios from "axios";
import endpoints from "../endpoints";
import { useErrorHandling } from "@/modules/core/composables";

export const useBroadcastPortalAdvertisementListingStore = defineStore('broadcast-portal-advertisement-listing', {
    state: () => ({
        paginatedData: null as PaginatedData<Advertisement>|null,
        filters: {
            searchTerms: '',
            pageNumber: 1,
            pageSize: 9
        },
        isBusy: false
    }),
    actions: {
        async loadAdvertisement () {
            this.isBusy = true;

            const request = axios.get<APIResponse<PaginatedData<Advertisement>>>(
                `/api/v1/advertisements/search`,
                { params: this.filters }
            )

            const response = await useErrorHandling(request)

            this.paginatedData = response.data.data
            this.isBusy = false
        }
    }
})