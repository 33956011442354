import { defineStore } from "pinia";
import { APIResponse, PaginatedData } from "@/modules/core/types";
import { BcfListing } from "../types";
import axios from "axios";
import { useErrorHandling } from "@/modules/core/composables";

export const useBroadcastPortalBcfListingStore = defineStore('broadcast-portal-bcf-listing-store', {
    state: () => ({
        paginatedData: null as PaginatedData<BcfListing>|null,
        filters: {
            keywords: '',
            pageNumber: 1,
            pageSize: 9,
            categoryType: '',
            subCategory: '',
            submissionYear: 2024,
        },
        isBusy: false
    }),
    actions: {
        async loadBcfListing () {
            this.isBusy = true;

            const request = axios.get<APIResponse<PaginatedData<BcfListing>>>(
                `/api/v1/bcf-submissions`,
                { params: this.filters }
            )

            const response = await useErrorHandling(request)
            this.paginatedData = response.data.data
            this.isBusy = false
        }
    }
})