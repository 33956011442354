<!-- We dont need a template as the template is in the cshtml file -->

<script lang="ts">
import { defineComponent } from 'vue';
import { Icon } from '@/modules/core/components'
import BcfListing from './bcf-listing.vue'

export default defineComponent({
    components: {
        Icon,
        BcfListing
    }
})
</script>
