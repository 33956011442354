<!-- We dont need a template as the template is in the cshtml file -->

<script lang="ts">
import { defineComponent } from 'vue'
import { GenericCarousel, Icon, VLazyImage } from '@/modules/core/components';
import LatestAdvertisementCarousel from './latest-advertisement-carousel.vue';

export default defineComponent({
    components: {
        GenericCarousel,
        Icon,
        VLazyImage,
        LatestAdvertisementCarousel,
    }
})
</script>
