export const getCategoryFullName = (abbreviatedCategory: string): string => {
    const categoryMap: Record<string, string> = {
        'drama': 'Drama',
        'comedy': 'Comedy',
        'horror': 'Horror',
        'advertisement': 'Advertisement',
        'documentary': 'Documentary',
        'psa': 'Public Service Announcement',
        'musicvideo': 'Music Video',
        'featured': 'Featured Film',
        'shortfilm': 'Short Film',
        'shortfilmihl': 'Short Film under IHL',
        'IHL-Documentary': 'Documentary (IHL)',
    };

    return categoryMap[abbreviatedCategory] || abbreviatedCategory;
}