<!-- We dont need a template as the template is in the cshtml file -->

<script lang="ts">
import { defineComponent } from 'vue'
import AdvertisementFinder from './advertisement-finder.vue'

export default defineComponent({
    components: {
        AdvertisementFinder
    }
})
</script>
