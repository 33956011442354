<template>
    <div class="gray-bg block">
        <div class="container">
            <div class="block__header">
                <h2>Latest Advertisement</h2>
            </div>
        </div>
        <FadeInOut mode="out-in" :duration="300">
            <GridPlaceholder
                v-if="isBusy"
                grid-config="3x1"
                grid-config-lg="3x1"
                grid-item-ratio="4x3"
                class="container"
            ></GridPlaceholder>
            <div v-else>
                <div v-if="paginatedData?.items.length !== 0">
                    <div class="latest-advertisement block__body mb-5 overflow-hidden">
                        <div class="container">
                            <GenericCarousel
                                class="slides-equal-height"
                                swiper-class="py-4 overflow-visible"
                                :config="{
                                    slidesPerView: 1,
                                    spaceBetween: 24,
                                    autoplay: {
                                        delay: 4200,
                                        disableOnInteraction: false,
                                    },
                                    breakpoints:
                                        {
                                            720:
                                                {
                                                    slidesPerView: 2
                                                },
                                            1200:
                                                {
                                                    slidesPerView: 3,
                                                    initialSlide: 1
                                                },
                    
                                        }
                                }">
                                <div v-for="(item, index) in paginatedData?.items.slice(0, 5)" class="swiper-slide">
                                    <div class="card border-0 shadow h-100 bg-transparent">
                                        <div class="card-body p-0 vstack">
                                            <div class="position-relative">
                                                <!-- <div class="badge-featured position-absolute">
                                                    <div class="badge bg-secondary rounded-pill px-2">
                                                        Featured
                                                    </div>
                                                </div> -->
                                                <div v-if="item.advertisement.advertisementImages && item.advertisement.advertisementImages.length === 1" class="ratio ratio-16x9">
                                                <VLazyImage
                                                    :src="item.advertisement.advertisementImages[0].url"
                                                    :alt="item.advertisement.advertisementImages[0].name"
                                                    class="v-lazy-image-fades-in"
                                                ></VLazyImage>
                                                </div>
                                                <SwiperVue
                                                    v-else-if="item.advertisement.advertisementImages && item.advertisement.advertisementImages.length > 1"
                                                    :modules="[PaginationCarousel, Navigation]"
                                                    :navigation="true"
                                                    class="swiperButton"
                                                >
                                                    <SwiperSlide v-for="image in item.advertisement.advertisementImages">
                                                        <div class="ratio ratio-16x9">
                                                            <VLazyImage
                                                                :src="image.url"
                                                                :alt="image.name"
                                                                class="v-lazy-image-fades-in"
                                                            ></VLazyImage>
                                                        </div>
                                                    </SwiperSlide>
                                                </SwiperVue>
                                                <div v-else-if="!item.advertisement.advertisementImages || (item.advertisement.advertisementImages && item.advertisement.advertisementImages.length === 0)" class="ratio ratio-16x9">
                                                    <div class="bg-gray-200 d-flex align-items-center justify-content-center">
                                                        <p class="mb-0 heading-s text-muted">No Image</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="bg-white p-3 vstack h-100 justify-content-between">
                                                <AdvertisementDetailModal :advertisement="item.advertisement" :index="index">
                                                    <template #member-info>
                                                        <img :src="item.logoIcon.url" :alt="item.logoIcon.name" class="img-fluid img-company-logo">
                                                        <p class="caption fw-semibold text-muted mb-0">{{ item.memberName }}</p>
                                                    </template>
                                                </AdvertisementDetailModal>
                                                <div class="d-flex flex-column justify-content-end">
                                                    <div class="hstack gap-2 mb-3">
                                                        <img :src="item.logoIcon.url" :alt="item.logoIcon.name" class="img-fluid img-company-logo">
                                                        <p class="caption fw-semibold text-muted mb-0">{{ item.memberName }}</p>
                                                    </div>
                                                    <div class="hstack gap-2">
                                                        <Icon symbol="calendar" class="icon-l"></Icon>
                                                        <p class="caption text-gray-500 mb-0">
                                                            {{ formatDateAsString(item.advertisement.startDate, 'DD MMM YYYY') }} - {{ formatDateAsString(item.advertisement.endDate, 'DD MMM YYYY') }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </GenericCarousel>
                        </div>
                    </div>
                    <div class="container">
                        <div class="d-flex justify-content-center">
                            <a href="/advertisement" class="button button--default button--theme button--solid">View All</a>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <p class="body-l text-center my-4">There are no advertisements to show at this time.</p>
                </div>
            </div>
        </FadeInOut>
    </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useBroadcastPortalAdvertisementListingStore } from '../../store/advertisements';
import { GenericCarousel, GridPlaceholder, Icon, VLazyImage } from '@/modules/core/components';
import { formatDateAsString } from '@/modules/core/utilities';
import {Swiper, Pagination as PaginationCarousel, Navigation} from "swiper";
import { Swiper as SwiperVue, SwiperSlide } from "swiper/vue";

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import AdvertisementDetailModal from './advertisement-detail-modal.vue';
import { FadeInOut } from 'vue3-transitions';

const advertisementListingStore = useBroadcastPortalAdvertisementListingStore()
const { filters, paginatedData, isBusy } = storeToRefs(advertisementListingStore)

filters.value.searchTerms = ''

advertisementListingStore.loadAdvertisement()
</script>

<style lang="scss">
.latest-advertisement {
    .swiperButton > :nth-child(2), .swiperButton > :nth-child(3) {
        --swiper-navigation-size: 12px;
        --swiper-navigation-color: #6c757d;
        
        background-color: white;
        padding: 0.45rem 0.6rem;
        border-radius: 999px;
        outline: 1px solid $gray-400;
    }

    .img-company-logo {
        max-height: 20px;
        max-width: 100px;
    }
}
</style>