<template>
    <div class="gray-bg py-5">
        <div class="container">
            <div class="row gy-3">
                <h1 class="heading-l fw-bold">Advertisement</h1>
                <div class="col-md-10">
                    <input
                        id="txt-searchTerms"
                        name="searchTerms"
                        placeholder="Search through your advertisements..."
                        v-model.lazy="filters.searchTerms"
                        @change="onSearchChange"
                        type="text">
                </div>
                <div class="col-md-2">
                    <button @click="onSearchChange" class="btn btn-secondary btn-lg w-100" type="button">Search</button>
                </div>
            </div>
        </div>
    </div>
    <div class="advertisement-finder container pt-5 py-4">
        <FadeInOut mode="out-in" :duration="300">
            <GridPlaceholder
                v-if="isBusy"
                grid-config="1x3"
                grid-config-lg="3x2"
                grid-item-ratio="4x3"
            ></GridPlaceholder>
            <div v-if="paginatedData && paginatedData.count > 0">
                <p class="subheading text-muted">{{ paginationStatusLabel }}</p>
                <div class="row gy-3">
                    <div v-if="paginatedData && paginatedData.items" v-for="(item, index) in paginatedData?.items" class="col-md-6 col-lg-4">
                        <div class="card rounded-3 border-1 h-100 bg-transparent">
                            <div class="card-body p-3 vstack">
                                <div class="position-relative">
                                    <div v-if="index < 3" class="badge-featured position-absolute">
                                        <div class="badge bg-secondary rounded-pill px-2">
                                            Featured
                                        </div>
                                    </div>
                                    <div class="ratio ratio-1x1">
                                        <VLazyImage
                                            v-if="item.advertisement.advertisementImages && item.advertisement.advertisementImages.length > 0"
                                            :src="item.advertisement.advertisementImages[0].url"
                                            :alt="item.advertisement.advertisementImages[0].name"
                                            class="v-lazy-image-fades-in object-fit-contain"
                                        ></VLazyImage>
                                        <div v-else class="bg-gray-200 d-flex align-items-center justify-content-center">
											<p class="mb-0 heading-s text-muted">No Image</p>
										</div>
                                    </div>
                                </div>
                                <div class="bg-white vstack h-100 justify-content-between">
                                    <div>
                                        <AdvertisementDetailModal :advertisement="item.advertisement" :index="index">
                                            <template #member-info>
                                                <img :src="item.logoIcon.url" :alt="item.logoIcon.name" class="img-fluid img-company-logo">
                                                <p class="caption fw-semibold text-muted mb-0">{{ item.memberName }}</p>
                                            </template>
                                        </AdvertisementDetailModal>
                                        <p class="body-s text-gray-500 line-clamp" style="white-space:pre-wrap; --line-clamp-lines: 3">{{ item.advertisement.description }}</p>
                                    </div>
                                    <div class="d-flex flex-column justify-content-end">
                                        <div class="hstack gap-2 mb-3">
                                            <img v-if="item.logoIcon" :src="item.logoIcon.url" :alt="item.logoIcon.name" class="img-fluid img-company-logo">
                                            <p class="caption fw-semibold text-muted mb-0">{{ item.memberName }}</p>
                                        </div>
                                        <div class="hstack gap-3 flex-wrap">
                                            <div class="hstack gap-2">
                                                <Icon symbol="pin-map-fill" class="icon-m text-muted"></Icon>
                                                <p class="caption text-gray-500 mb-0">{{ item.advertisement.venue }}</p>
                                            </div>
                                            <div class="hstack gap-2">
                                                <Icon symbol="calendar" class="icon-m"></Icon>
                                                <p class="caption text-gray-500 mb-0">
                                                    {{ formatDateAsString(item.advertisement.startDate, 'DD MMM YYYY') }} - {{ formatDateAsString(item.advertisement.endDate, 'DD MMM YYYY') }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <p class="display-l text-muted">No advertisement can be found...</p>
            </div>
        </FadeInOut>
        <div class="d-flex pt-4">
            <Pagination
                v-if="paginatedData?.currentPageNumber"
                :page-number="paginatedData?.currentPageNumber"
                :total-pages="paginatedData?.totalPages"
                :pages-per-view="paginatedData.pageSize"
                @page-change="onPageChange"
            ></Pagination>
        </div>
    </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { formatDateAsString } from '@/modules/core/utilities';
import { FadeInOut } from 'vue3-transitions';
import { computed } from 'vue';
import { useBroadcastPortalAdvertisementListingStore } from '../../store/advertisements';
import { GridPlaceholder, Icon, Modal, Pagination, VLazyImage } from '@/modules/core/components';
import AdvertisementDetailModal from './advertisement-detail-modal.vue';


const advertisementListingStore = useBroadcastPortalAdvertisementListingStore()
const { filters, isBusy, paginatedData } = storeToRefs(advertisementListingStore)
    
const paginationStatusLabel = computed(() => {
    if (!paginatedData) return '';
    const min = ((paginatedData.value!.currentPageNumber - 1) * paginatedData.value!.pageSize) + 1;
    const max = Math.min(paginatedData.value!.currentPageNumber * paginatedData.value!.pageSize, paginatedData.value!.count);

    return `Showing ${min}-${max} out of ${paginatedData.value!.count}`;
})

const onPageChange = (pageNumber: number) => {
    filters.value.pageNumber = pageNumber;
    advertisementListingStore.loadAdvertisement();
}

const onSearchChange = () => {
    advertisementListingStore.filters.pageNumber = 1;
    advertisementListingStore.loadAdvertisement();
}

    advertisementListingStore.loadAdvertisement()
</script>

<style lang="scss">
.advertisement-finder {
    .img-company-logo {
        max-height: 20px;
        max-width: 100px;
    }

    .badge-featured {
        top: 4%;
        left: 4%;
        z-index: 1;
    }
}

#modals {
    .modal-content {
        .card-advertisement {
            .badge-featured {
                top: 4%;
                left: 4%;
                z-index: 2;
            }
        }
    }
}
</style>
