<!-- We dont need a template as the template is in the cshtml file -->

<script lang="ts">
import { defineComponent } from 'vue';
import { Tabs, TabPane, Icon, GenericCarousel, Avatar, VLazyImage, Modal } from '@/modules/core/components';
import FindMemberShowPortfolioVideo from './find-member-show-portfolio-video.vue';
import { SwiperSlide } from "swiper/vue";
import { AdvertisementImageCarousel } from '@/modules/broadcast-portal/components/advertisement/index'

export default defineComponent({
    components: {
        Icon,
        GenericCarousel,
        Avatar,
        Tabs,
        TabPane,
        VLazyImage,
        Modal,
        FindMemberShowPortfolioVideo,
        SwiperSlide,
        AdvertisementImageCarousel,
    }
})
</script>
