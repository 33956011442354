import "./scss/core.scss";
import "./scss/components/index.scss";
import "./scss/_global.scss";
// import "./scss/index.scss";
import 'virtual:svg-icons-register';
import pinia from "./store";
import { createApp } from 'vue';
import { 
    FindServicesApp, 
    ServiceDetailsApp, 
    FindMembersApp, 
    MemberDetailsApp, 
    FindAdvertisementApp,
    AdvertisementCarouselApp,
    BcfListingApp,
    AdvertisementApp,
} from './components';

createApp(FindServicesApp).use(pinia).mount('.find-services-app')
createApp(ServiceDetailsApp).use(pinia).mount('.service-details-app')
createApp(FindMembersApp).use(pinia).mount('.find-members-app')
createApp(MemberDetailsApp).use(pinia).mount('.member-details-app')
createApp(FindAdvertisementApp).use(pinia).mount('.find-advertisement-app')
createApp(AdvertisementCarouselApp).use(pinia).mount('.advertisement-carousel-app')
createApp(BcfListingApp).use(pinia).mount('.bcf-listing-app')
createApp(AdvertisementApp).use(pinia).mount('.advertisement-app')

// configureAxios();