<template>
    <span v-if="showNewLabel" class="advertisement-badge"> New </span>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useBroadcastPortalAdvertisementListingStore } from '../../store/advertisements';
import { onMounted, ref } from 'vue';

interface Props {
    advertisementNumberOfDaysMs: number;
}

const props = defineProps<Props>()

const advertisementListingStore = useBroadcastPortalAdvertisementListingStore();
const { paginatedData } = storeToRefs(advertisementListingStore);

const showNewLabel = ref(false);

onMounted(async () => {
    await advertisementListingStore.loadAdvertisement();
    updateNewLabel();
});

const updateNewLabel = () => {
    const advertisementCreateDate = paginatedData.value?.items[0]?.advertisement?.lastUpdateDateTime;
    if (!advertisementCreateDate) {
        showNewLabel.value = false;
        return;
    }
    const currentDate = new Date().getTime();

    showNewLabel.value = currentDate - new Date(advertisementCreateDate).getTime() <= props.advertisementNumberOfDaysMs;
}
</script>

<style scoped>
.advertisement-badge {
    background-color:#0058ff;
    color: white;
    font-size: 9px;
    font-weight: 300;
    letter-spacing: 1px;
    padding: 0 8px;
    border-radius: 15px;
    position: absolute;
    right: -5px;
    top: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    height: 18px;
}
</style>