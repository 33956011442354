<!-- We dont need a template as the template is in the cshtml file -->

<script lang="ts">
import { defineComponent } from 'vue';
import MembersFinder from './members-finder.vue';
import { Icon } from '@/modules/core/components';

export default defineComponent({
    components: {
        MembersFinder,
        Icon
    }
})
</script>
